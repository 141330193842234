<template>
  <div class="border border-1 dark:border-slate-700 rounded py-3 px-3">
    <HeadlessTabGroup>
      <HeadlessTabList class="lg:space-x-8 md:space-x-4 space-x-0">
        <HeadlessTab
          v-slot="{ selected }"
          as="template"
          v-for="(item, i) in buttons"
          :key="i"
        >
          <button
            :class="[
              selected
                ? 'text-slate-950 dark:text-slate-300 before:w-full'
                : 'text-slate-500 before:w-0 dark:text-slate-500',
            ]"
            class="text-lg lg:text-xl font-medium mb-7 capitalize ring-0 focus:ring-0 focus:outline-none px-2 transition duration-150 before:transition-all before:duration-150 relative before:absolute before:left-1/2 before:bottom-[-6px] before:h-[1.5px] before:bg-slate-900 dark:before:bg-slate-300 before:-translate-x-1/2"
          >
            {{ item.title }}
          </button>
        </HeadlessTab>
        <HeadlessTabPanels>
          <HeadlessTabPanel>
            <div class="text-slate-950 dark:text-slate-300 font-normal">
              <div>{{ productStore.description }}</div>
              <div class="mt-3 font-bold">1. Permanent Denture :</div>
              <div class="text-slate-950 dark:text-slate-300">
                Permanent dentures are a type of denture that is specially made
                so that it can be attached to the oral cavity without having to
                be removed or reattached.
              </div>
              <ul class="mt-1 list-disc ml-5">
                <li class="text-slate-950 dark:text-slate-300">
                  Dental Bridge (Denture Bridge)
                </li>
                <li class="text-slate-950 dark:text-slate-300">
                  Dental Implant
                </li>
              </ul>
              <!-- A -->
              <div class="mt-3 font-bold">2. Removable Denture :</div>
              <div class="text-slate-950 dark:text-slate-300">
                Removable dentures or commonly called false teeth are a type of
                denture that can be removed and installed, more suitable for
                cases of multiple tooth loss.
              </div>
              <ul class="mt-1 list-disc ml-5">
                <li class="text-slate-950 dark:text-slate-300">
                  Acrylic dentures
                </li>
                <li class="text-slate-950 dark:text-slate-300">
                  Valplast dentures
                </li>
                <li class="text-slate-950 dark:text-slate-300">
                  Metal framework dentures
                </li>
              </ul>
              <!-- B -->
              <div class="mt-3 text-lg font-bold text-center justify-center">
                PERMANENT DENTURE
              </div>
              <div class="mt-3 font-semibold">🔸 DENTAL BRIDGE</div>
              <div class="text-slate-950 dark:text-slate-300">
                Dental bridge or bridge denture is a type of permanent denture
                supported by the natural teeth next to it. Therefore, the
                supporting teeth next to it must be reduced first. Usually one
                missing tooth is supported by 2 natural teeth. The cost of a
                dental bridge is calculated based on the number of missing teeth
                and supporting teeth.
              </div>
              <div class="mt-3 font-semibold">🔸DENTAL IMPLANT</div>
              <div class="text-slate-950 dark:text-slate-300">
                Dental implants are a type of permanent artificial tooth that is
                implanted into the gums and bones so that it functions the same
                as a real tooth. The components of the implant consist of an
                implant (the part that is implanted into the gums), an abutment
                (the part that connects the implant and the crown) and a crown.
                Dental implants do not require support from adjacent teeth, so
                there is no need to sharpen the adjacent teeth.
              </div>
              <!-- C -->
              <div class="mt-3 text-lg font-bold text-center justify-center">
                REMOVABLE DENTURE
              </div>
              <div class="mt-3 font-semibold">🔸 ACRYLIC DENTURE</div>
              <div class="text-slate-950 dark:text-slate-300">
                Acrylic dentures are the most common and economical type. They
                tend to be thicker and use wire clasps to hold them to the
                remaining teeth.
              </div>
              <div class="mt-3 font-semibold">🔸 VALPLAST DENTURE</div>
              <div class="text-slate-950 dark:text-slate-300">
                Valplast / flexi removable dentures are a type that is more
                comfortable to use because they are thinner, more flexible, and
                do not use wire clasps.
              </div>
              <div class="mt-3 font-semibold">🔸 METAL FRAME DENTURE</div>
              <div class="text-slate-950 dark:text-slate-300">
                Metal frame removable dentures are the most comfortable type to
                use. The frame is made of high-quality metal, can be made
                thinner, stronger, and more durable. This type of metal frame
                can also be combined with valplast material (price adjusted) to
                get comfortable and aesthetic dentures.
              </div>
              <div
                class="flex flex-col py-1 pb-3 px-5 rounded-sm border border-gray-600 dark:border-gray-500 mt-5"
              >
                <div class="mt-3 font-bold">Advantage :</div>
                <ul class="mt-1 list-disc ml-5">
                  <li class="text-slate-950 dark:text-slate-300">
                    Dental veneer lasts up to 15 years.
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    Its longevity depends on the care and attention you give to
                    all your teeth
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    Dental veneer is highly durable
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    It comes in thin layer
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    It prevents the need for drilling the teeth
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    Looks natural, like your own teeth.
                  </li>
                </ul>
                <div class="mt-3 font-bold">Note :</div>
                <ul class="mt-1 list-decimal ml-5">
                  <li class="text-slate-950 dark:text-slate-300">
                    Due to the different monitor and light effect, the actual
                    color of the item might be slightly different from the color
                    showed on the pictures. Thank you!
                  </li>
                  <li class="text-slate-950 dark:text-slate-300">
                    Please allow slight measuring deviation due to manual
                    measurement.
                  </li>
                </ul>
              </div>
            </div>
          </HeadlessTabPanel>
          <HeadlessTabPanel>
            <div class="relative px-3">
              <div class="mt-3 font-bold">Video Review :</div>
              <div class="text-slate-950 dark:text-slate-300">
                It's this easy how they use it
              </div>
              <div
                class="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 items-center justify-center gap-3 w-full mt-3 bg-gray-100 dark:bg-slate-900"
              >
                <div
                  class="col-span-1 flex w-auto h-[360px] rounded-sm border border-gray-500 dark:border-gray-600"
                >
                  <video
                    ref="video-player"
                    id="video-player"
                    src="https://res.cloudinary.com/deh7azkfe/video/upload/v1730462291/dentestore/tnkwqjd5cpwttounl8ia.mp4"
                    class="h-full w-full"
                    :controls="true"
                    :autoplay="false"
                  ></video>
                </div>
                <div
                  class="col-span-1 flex w-auto h-[360px] rounded-sm border border-gray-500 dark:border-gray-600"
                >
                  <video
                    ref="video-player"
                    id="video-player"
                    src="https://res.cloudinary.com/deh7azkfe/video/upload/v1730462430/dentestore/brg4284xnxz4k5pcbzht.mp4"
                    class="h-full w-full"
                    :controls="true"
                    :autoplay="false"
                  ></video>
                </div>
              </div>
            </div>
          </HeadlessTabPanel>
        </HeadlessTabPanels>
      </HeadlessTabList>
    </HeadlessTabGroup>
  </div>
</template>

<script setup lang="ts">
import { productStore } from "#imports";

onMounted(async () => {});
const buttons = [
  {
    title: "Description",
  },
  {
    title: "Video Review",
  },
];
</script>

<style scoped>
@keyframes progress {
  0% {
    @apply bg-red-500;
  }
  50% {
    @apply bg-red-600;
  }
  75% {
    @apply bg-red-700;
  }
  100% {
    @apply bg-red-800;
  }
}
@-webkit-keyframes progress {
  0% {
    @apply bg-red-500;
  }
  50% {
    @apply bg-red-600;
  }
  75% {
    @apply bg-red-700;
  }
  100% {
    @apply bg-red-800;
  }
}
.progress {
  @apply h-full w-full rounded-full transition-all ease-in-out duration-150;
  animation: progress 2s infinite linear;
}
</style>
